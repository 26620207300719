import constate from "constate";
import { UsableAccount, HcssCalendarAccount } from "./models";
import { notify } from "hcss-components";
import { useHcssUser, useHcssToken } from "modules/account";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HcssConnectAccountService } from "./services/account-service";
import config from "config";
import { UserType } from "core";
import { NylasCalendar } from "./models/NylasCalendar";
import { strings } from "localization";

const useHcssConnect = () => {
  const token = useHcssToken();
  const user = useHcssUser();
  const [loadingNylasAccounts, setLoadingNylasAccounts] = useState<boolean>(
    true
  );
  const [
    loadingHcssCalendarAccounts,
    setLoadingHcssCalendarAccounts
  ] = useState<boolean>(true);
  const [loadingNylasCalendars, setLoadingNylasCalendars] = useState<boolean>(
    true
  );
  const [nylasAccounts, setNylasAccounts] = useState<UsableAccount[]>([]);
  const [nylasCalendars, setNylasCalendars] = useState<NylasCalendar[]>([]);

  const hcssConnectApi = useMemo(
    () => new HcssConnectAccountService(config.endpoints.HCSSCONNECT, token),
    [token]
  );

  const loadHcssCalendarAccounts = useCallback(async () => {
    setLoadingHcssCalendarAccounts(true);
    try {
      const accs = await hcssConnectApi.getHcssCalendarAccounts();
      setLoadingHcssCalendarAccounts(false);
      return accs.data;
    } catch (error) {
      console.error(error);
      notify("danger", strings.hcssConnect.errors.fetchingAccount);
      setLoadingHcssCalendarAccounts(false);
    }
  }, [hcssConnectApi]);

  const loadNylasAccounts = useCallback(
    async (hcssAccounts: HcssCalendarAccount[]) => {
      setLoadingNylasAccounts(true);
      try {
        const activeHcssAccounts = hcssAccounts.filter(
          account => account.isActive
        );
        const accounts = await hcssConnectApi.getNylasAccounts(
          activeHcssAccounts
        );
        if (accounts.some(acc => acc.isBad)) {
          notify("danger", strings.hcssConnect.errors.fetchingAccount);
        }
        setNylasAccounts(accounts);
      } catch (error) {
        console.error(error);
        notify("danger", strings.hcssConnect.errors.fetchingAccount);
      }
      setLoadingNylasAccounts(false);
    },
    [hcssConnectApi]
  );

  const loadNylasCalendars = useCallback(async () => {
    setLoadingNylasCalendars(true);
    try {
      const calendars = await hcssConnectApi.getNylasCalendarsForNylasAccounts(
        nylasAccounts
      );
      setNylasCalendars(calendars);
    } catch (error) {
      console.error(error);
      notify("danger", "Error fetching calendars");
    }
    setLoadingNylasCalendars(false);
  }, [nylasAccounts, hcssConnectApi]);

  const loadAccounts = useCallback(async () => {
    const hcssCalendarAccounts = await loadHcssCalendarAccounts();
    if (hcssCalendarAccounts) {
      await loadNylasAccounts(hcssCalendarAccounts);
    }
  }, [loadHcssCalendarAccounts, loadNylasAccounts]);

  useEffect(() => {
    if (user.type !== UserType.Guest) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadAccounts();
    }
  }, [loadAccounts, user.type]);

  useEffect(() => {
    if (nylasAccounts) void loadNylasCalendars();
  }, [nylasAccounts, loadNylasCalendars]);

  return {
    loading:
      loadingHcssCalendarAccounts ||
      loadingNylasAccounts ||
      loadingNylasCalendars,
    nylasAccounts,
    nylasCalendars,
    loadNylasCalendars,
    loadAccounts
  };
};

const [provider, context] = constate(useHcssConnect);
export const HcssConnectProvider = provider;
export const useHcssConnectContext = context;
