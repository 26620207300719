import produce from "immer";
import { ActionType, createAction, getType } from "typesafe-actions";

export const STATE_KEY = "settings";

// Models
export interface State {
  emailPanelActive: boolean;
  businessUnitPanelActive: boolean;
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  toggleEmailPanel: createAction("SETTINGS/TOGGLE_EMAIL_PANEL", resolve => {
    return (show?: boolean) => resolve({ show });
  }),
  toggleBUPanel: createAction("SETTINGS/TOGGLE_BU_PANEL", resolve => {
    return (show?: boolean) => resolve({ show });
  })
};

export type SettingsActions = ActionType<typeof actions>;

const initialState: State = {
  emailPanelActive: false,
  businessUnitPanelActive: false
};

// Reducer
export const reducer = (state = initialState, action: SettingsActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.toggleEmailPanel): {
        const { show } = action.payload;
        if (show !== undefined) {
          draft.emailPanelActive = show;
        } else {
          draft.emailPanelActive = !draft.emailPanelActive;
        }
        break;
      }
      case getType(actions.toggleBUPanel): {
        const { show } = action.payload;
        if (show !== undefined) {
          draft.businessUnitPanelActive = show;
        } else {
          draft.businessUnitPanelActive = !draft.businessUnitPanelActive;
        }
        break;
      }
    }
  });
};

export type SelectorState = StateSlice;

// Selectors
const getEmailPanelActive = ({ settings }: SelectorState) =>
  settings.emailPanelActive;
const getBUPanelActive = ({ settings }: SelectorState) =>
  settings.businessUnitPanelActive;

export const selectors = {
  getEmailPanelActive,
  getBUPanelActive
};
