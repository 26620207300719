import React, { useEffect } from "react";
import { Modal } from "hcss-components";
import config from "config";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "nylas-authentication-component": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface NylasAuthenticationComponentProps {
  onHide: () => void;
  show: boolean;
}

export const NylasAuthenticationComponent = ({
  onHide,
  show
}: NylasAuthenticationComponentProps) => {
  useEffect(() => {
    import(
      /* webpackIgnore: true */ `${config.endpoints.HCSSCONNECT}/authcomponent/web-component/nylasauthentication-webcomponent/nylasauthentication-webcomponent.esm.js`
    );
  }, []);

  return (
    <Modal
      onHide={onHide}
      show={show}
      style={{ zIndex: 2010 }}
      backdropStyle={{ zIndex: 2010 }}
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <nylas-authentication-component />
      </Modal.Body>
    </Modal>
  );
};
