import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { View } from "../../../api";
import { selectors, actions } from "../state";
import { ModalPanel, ModalPanelProps } from "core/components/modals/SideModal";
import { ContentLoadingOverlay } from "core/components/loaders";
import { strings } from "localization";
import { CalendarSubscriptionCard } from "./CalendarSubscriptionCard";
import { InactiveOptionsButton } from "./InactiveOptionsButton";
import { ActiveOptionsButton } from "./ActiveOptionsButton";
import { ReadOnlyCalendarContent } from "./ReadonlyCalendarContent";
import {
  useHcssConnectContext,
  NylasAuthenticationComponent
} from "core/services/hcss-connect";
import { track } from "logging";
import { NylasCalendarContent } from "./NylasCalendarContent";

interface CalendarSubscribePanelProps extends ModalPanelProps {
  views?: View[];
}
export const CalendarSubscribePanel = ({
  isActive,
  onHide,
  views
}: CalendarSubscribePanelProps) => {
  const { nylasCalendars } = useHcssConnectContext();
  const dispatch = useDispatch();
  const handleDelete = (calendarId: string) => {
    dispatch(actions.deleteCalendar.request(calendarId));
  };
  const { loadAccounts } = useHcssConnectContext();
  const [showNylasAuth, setShowNylasAuth] = useState(false);
  const openNylasAuth = useCallback(() => {
    setShowNylasAuth(true);
  }, [setShowNylasAuth]);
  const closeNylasAuth = useCallback(async () => {
    await loadAccounts();
    setShowNylasAuth(false);
  }, [loadAccounts, setShowNylasAuth]);

  const handleCreateReadonlyLink = (viewId: string) => {
    track("CALENDAR CONNECTED", {
      module: "projects",
      redux: false,
      type: "iCal"
    });
    dispatch(
      actions.saveCalendar.request({
        viewId,
        isNylas: false
      })
    );
  };

  const handleCreateNylasLink = (viewId: string) => {
    dispatch(
      actions.saveCalendar.request({
        viewId,
        isNylas: true
      })
    );
  };

  const calendars = useSelector(selectors.getCalendarsByViewId);

  const loaded = useSelector(selectors.getLoaded);

  return (
    <div>
      <NylasAuthenticationComponent
        onHide={closeNylasAuth}
        show={showNylasAuth}
      />
      <StyledPanel
        className="email-settings-panel"
        isActive={isActive}
        onHide={onHide}
      >
        <ModalPanel.Header
          onHide={onHide}
          panelTitle={strings.calendars.subscribePanel.title}
        />
        <ModalPanel.Body>
          <TitleLabel>{strings.calendars.subscribePanel.links}</TitleLabel>
          <span>
            <TitleLabel>{strings.calendars.subscribePanel.visible}</TitleLabel>
          </span>
          <span>
            <TitleLabel style={{ fontWeight: 700 }}>
              <OpenAccountSettingsButton onClick={openNylasAuth}>
                {strings.calendars.subscribePanel.openAccountSettingsButton}
              </OpenAccountSettingsButton>
              {strings.calendars.subscribePanel.openAccountSettingsAfter}
            </TitleLabel>
          </span>
          {!loaded || !views ? (
            <ContentLoadingOverlay />
          ) : (
            <StyledDivWrapper>
              {views.map((view: View) => {
                const calendarsForView = calendars[view.id];
                const readOnlyCalendar = calendarsForView?.iCal;
                const nylasCalendar = calendarsForView?.nylas;
                return (
                  <CalendarSubscriptionCard
                    key={view.id}
                    templateName={view.name}
                    OptionsButton={
                      readOnlyCalendar || nylasCalendar ? (
                        <ActiveOptionsButton
                          templateId={view.id}
                          readonlyCalendar={readOnlyCalendar}
                          nylasCalendar={nylasCalendar}
                          onDelete={handleDelete}
                          onCreateReadonlyCalendar={handleCreateReadonlyLink}
                          onCreateNylasLink={handleCreateNylasLink}
                        />
                      ) : (
                        <InactiveOptionsButton
                          templateId={view.id}
                          onCreateReadonlyLink={handleCreateReadonlyLink}
                          onCreateNylasLink={handleCreateNylasLink}
                        />
                      )
                    }
                    CardContent={
                      <>
                        {nylasCalendar && (
                          <NylasCalendarContent
                            viewId={view.id}
                            nylasCalendar={nylasCalendar}
                            nylasCalendars={nylasCalendars}
                          />
                        )}
                        {readOnlyCalendar && (
                          <ReadOnlyCalendarContent
                            templateName={view.name}
                            readonlyCalendar={readOnlyCalendar}
                          />
                        )}
                      </>
                    }
                  />
                );
              })}
            </StyledDivWrapper>
          )}
        </ModalPanel.Body>
      </StyledPanel>
    </div>
  );
};

const StyledPanel = styled(ModalPanel)`
  z-index: 2002;

  .modal-panel-content {
    .modal-panel-body {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      overflow: hidden;
      padding: 0;

      .panel-instructions {
        position: relative;
        margin: 10px 0;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e1e;
      }
    }
  }
`;

const TitleLabel = styled.div`
  padding-left: 30px;
  margin-top: 16px;
`;
const OpenAccountSettingsButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #069;
  cursor: pointer;
`;

const StyledDivWrapper = styled.div`
  padding-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
