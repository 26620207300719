import React, { Fragment } from "react";
import styled from "styled-components";
import { OAuthConfigurationPanel } from "modules/settings/components/OAuthConfigurationPanel";
import { ProjectsCalendarSubscribeModal } from "modules/projects/components/ProjectsCalendarSubscribeModal";
import { BusinessUnitPanel } from "../../settings/components/BusinessUnitPanel";
import { HcssNavigation } from "core/components/hcss-navigation";
import { TopNavigation } from "./navigation/top-navigation";
import { SideNavigation } from "./navigation/side-navigation";
import { useModuleNavigation } from "./navigation/module";
import { useContextNavigation } from "./navigation/context";
import { useHcssUser } from "modules/account";
import { UserType } from "core";

interface SiteLayoutProps {
  children?: React.ReactNode;
}
export const SiteLayout = ({ children }: SiteLayoutProps) => {
  const user = useHcssUser();
  const { module: ModuleNavigation } = useModuleNavigation();
  const { context: ContextNavigation } = useContextNavigation();

  const hasModule = ModuleNavigation ? true : false;
  const hasContext = ContextNavigation ? true : false;
  const hasDynamicNavigation = hasModule || hasContext;
  const isGuestUser = user.type === UserType.Guest;
  const globalSideNavigation = isGuestUser ? undefined : SideNavigation;
  const useDynamicNavigation = isGuestUser ? false : hasDynamicNavigation;

  return (
    <FullHeightContainer>
      <HcssNavigation
        topOffset={0}
        flyoutOnHover={true}
        fullWidthFlyout={true}
        hideNavVisuallyOnCollapse={true}
        showGlobalSideNavShadow={true}
        useDynamicNavigation={useDynamicNavigation}
        globalTopNavigation={TopNavigation}
        globalSideNavigation={globalSideNavigation}
        moduleNavigation={ModuleNavigation ?? NoModule}
        contextNavigation={ContextNavigation}
      >
        <ContentWrapper>
          <Fragment>{children}</Fragment>
        </ContentWrapper>
      </HcssNavigation>
      <OAuthConfigurationPanel />
      <ProjectsCalendarSubscribeModal />
      <BusinessUnitPanel />
    </FullHeightContainer>
  );
};

const NoModule = () => null;

const FullHeightContainer = styled.div`
  height: 100vh;
  margin-top: 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default SiteLayout;
