import { Timestamp } from "firebase/firestore";
import { FileMeta } from "hcss-smartdrive";
import { SendEmailVia, SendEmailVia2 } from "../../api";
import { trim } from "lodash-es";
import { ContactDto } from "api/GeneratedClients/ContactsClient";
import { strings } from "localization";
import {
  Document,
  MessageType,
  QuoteItem,
  MessageStatus,
  Contact,
  NylasSyncState,
  Vendor
} from "core";

import {
  MinorityClass,
  QuoteVendorDto,
  QuoteItemDto
} from "api/GeneratedClients/EstimateHistoryClient";
import { isNullOrWhitespace } from "core/util";
import { UsableAccount } from "./hcss-connect";
import { NylasAccount } from "./hcss-connect/models/NylasAccount";

export class ModelMappingService {
  parseMinorityStatuses = (mstatuses?: MinorityClass[]) => {
    return (
      mstatuses
        ?.filter(ms => !isNullOrWhitespace(ms.code))
        .map(ms => trim(ms.code)) ?? []
    );
  };

  mapToContact = (contacts: ContactDto[]): Contact[] => {
    return contacts.map(c => ({
      firstName: c.firstName,
      lastName: c.lastName,
      email: c.emailAddress ?? "",
      cellPhone: c.cellPhoneNumber,
      phone: c.phoneNumber
    }));
  };

  mapVendorToContact = (vendor: QuoteVendorDto): Contact[] => {
    return [
      {
        firstName: vendor.firstName ?? "",
        lastName: vendor.lastName ?? "",
        cellPhone: vendor.cellPhone ?? "",
        email: vendor.email ?? "",
        phone: vendor.phone ?? ""
      }
    ];
  };

  mapToVendors = (vendors: QuoteVendorDto[]): Vendor[] => {
    return vendors.map(v => ({
      hbId: v.id,
      code: v.code,
      name: v.name ?? "",
      minorityStatuses: this.parseMinorityStatuses(v.minorityStatuses),
      contacts: this.mapVendorToContact(v)
    }));
  };

  mapToMasterSolicitationFilter = (from: string) => {
    switch (from.toLowerCase()) {
      case "drafts":
        return MessageStatus.Draft;
      case "sent":
        return MessageStatus.Sent;
      default:
        return undefined;
    }
  };

  mapToQuoteItem = (quoteItems: QuoteItemDto[]): QuoteItem[] => {
    return quoteItems.map(i => ({
      hbId: i.id,
      clientNo: i.clientNo || "",
      hbQuoteFolderId: i.quoteFolderId,
      description: i.alternateDescription || "",
      quantity: i.quantityAdditiveFactored,
      unit: i.unit || "",
      unitPrice: 0,
      total: 0,
      originalHbId: i.id
    }));
  };

  mapToIDocument = ({
    id,
    fileName,
    shareUrl,
    mime,
    size,
    created: cd,
    modified: md
  }: FileMeta): Document => {
    const created =
      cd !== null && cd !== undefined
        ? Timestamp.fromDate(new Date(cd))
        : Timestamp.now();

    const modified =
      md !== null && md !== undefined
        ? Timestamp.fromDate(new Date(md))
        : created;

    const url = shareUrl || "";

    return {
      id,
      fileName,
      url,
      mime,
      size,
      created,
      modified
    };
  };

  mapToIDocuments = (files: FileMeta[]) => {
    return files.map(f => this.mapToIDocument(f));
  };

  mapToMessageType = (from: string) => {
    switch (from) {
      case strings.solicitations.details.addendum:
        return MessageType.Addendum;
      case strings.solicitations.details.followUp:
        return MessageType.FollowUp;
      default:
        return MessageType.Initial;
    }
  };

  mapFromMessageType = (type: MessageType, isProposal = false) => {
    switch (type) {
      case MessageType.Addendum:
        return strings.solicitations.details.addendum;
      case MessageType.FollowUp:
        return strings.solicitations.details.followUp;
      default:
        return isProposal
          ? strings.quoteProposal.details.initialProposal
          : strings.solicitations.details.initialSolicitation;
    }
  };

  mapToSendEmailType2 = (method: SendEmailVia) => {
    switch (method) {
      case SendEmailVia.Mailgun:
        return SendEmailVia2.Mailgun;
      case SendEmailVia.OAuth:
        return SendEmailVia2.OAuth;
      case SendEmailVia.SMTP:
        return SendEmailVia2.SMTP;
    }
  };

  mapNylasAccountToUsableAccount = (
    nylasAccount: NylasAccount
  ): UsableAccount => {
    return {
      id: nylasAccount.id,
      emailAddress: nylasAccount.email,
      serviceName: nylasAccount.provider,
      created: new Date(nylasAccount.created_at * 1000),
      isBad: nylasAccount.grant_status === NylasSyncState.Invalid
    };
  };
}
